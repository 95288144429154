import React from "react";
import { InlineWidget } from "react-calendly";
import { FormattedMessage } from 'react-intl';
import { getCalendlyURL } from '../../utils/calendlyUtils.js';
import "./style.css";

const calendlyUrl = getCalendlyURL();

export const Web = () => {
    return (
        <div className="WEB">
            <header className="HEADER">
                <div className="frame">
                    <div className="logo">
                        <div className="logo-img">
                            <div className="overlap-group">
                                <img className="vector" alt="Vector" src="/img/vector-2-1.svg" />
                                <img className="img" alt="Vector" src="/img/vector-3-1.svg" />
                            </div>
                        </div>
                        <img className="logo-text" alt="Logo text" src="/img/logo-text.png" />
                    </div>
                    <div className="menu">
                        <div className="div-wrapper">
                            <div className="text-wrapper">Service</div>
                        </div>
                        <div className="div-wrapper">
                            <div className="text-wrapper">Demo</div>
                        </div>
                        <div className="div-wrapper">
                            <div className="text-wrapper">Pricing</div>
                        </div>
                        <div className="div">
                            <div className="text-wrapper-2">START TEST</div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="INTRO">
                <div className="overlap">
                    <img className="vector-2" alt="Vector" src="/img/vector-6.svg" />
                    <p className="seniors-are">&#34;<FormattedMessage id="digital_exclusion"
                        defaultMessage="Seniors are digitally excluded while students are digitally educated." />&#34;</p>
                    <div className="frame-2">
                        <div className="text-wrapper-3"><FormattedMessage id="test_now" defaultMessage="TEST NOW" /></div>
                    </div>
                    <img className="vector-3" alt="Vector" src="/img/vector-13-2.svg" />
                    <img
                        className="girl-teaching-her"
                        alt="Girl teaching her"
                        src="/img/girl-teaching-her-grandfather-how-use-laptop-1-1.png"
                    />
                </div>
            </div>
            <h1 className="h-1"><FormattedMessage id="help_digiplumber"
                defaultMessage="How Digiplumber works?" /></h1>
            <div className="STEPS">
                <div className="STEP">
                    <div className="overlap-group-2">
                        <div className="rectangle" />
                        <img className="vector-4" alt="Vector" src="/img/vector-12-1.svg" />
                        <p className="p"><FormattedMessage id="step1_description" defaultMessage="Client books a call on the calendar" /></p>
                        <img className="vector-5" alt="Vector" src="/img/vector-3.svg" />
                        <div className="frame-3">
                            <div className="text-wrapper-4"><FormattedMessage id="step1" defaultMessage="Step 1" /></div>
                        </div>
                    </div>
                </div>
                <div className="STEP-2">
                    <p className="text-wrapper-5"><FormattedMessage id="step2_description" defaultMessage="Call takes place between Digiplumber and client" /></p>
                    <div className="vector-wrapper">
                        <img className="vector-6" alt="Vector" src="/img/vector-2.svg" />
                    </div>
                    <div className="frame-4">
                        <div className="text-wrapper-4"><FormattedMessage id="step2" defaultMessage="Step 2" /></div>
                    </div>
                </div>
                <div className="STEP-2">
                    <p className="text-wrapper-6"><FormattedMessage id="step3_description" defaultMessage="Client installs and gives permission with OTP to make a call to demo" /></p>
                    <div className="frame-5">
                        <div className="text-wrapper-4"><FormattedMessage id="step3" defaultMessage="Step 3" /></div>
                    </div>
                    <div className="overlap-2">
                        <img className="vector-7" alt="Vector" src="/img/vector-7.svg" />
                        <img className="vector-8" alt="Vector" src="/img/vector-11.svg" />
                    </div>
                </div>
                <div className="STEP">
                    <div className="overlap-group-2">
                        <div className="rectangle" />
                        <p className="text-wrapper-7"><FormattedMessage id="step4_description" defaultMessage="Organise a simple session to validate client understanding" /></p>
                        <div className="frame-6">
                            <div className="text-wrapper-4"><FormattedMessage id="step4" defaultMessage="Step 4" /></div>
                        </div>
                        <img className="vector-9" alt="Vector" src="/img/vector-13.svg" />
                        <img className="vector-10" alt="Vector" src="/img/vector-10.svg" />
                    </div>
                </div>
            </div>
            <div className="CALENDAR" >
                <InlineWidget
                    url={calendlyUrl}
                    styles={{
                        height: '570px',
                        width: '100%'
                    }}
                // pageSettings={{
                //     backgroundColor: 'ffffff',
                //     hideEventTypeDetails: true,
                //     hideLandingPageDetails: true,
                //     primaryColor: '00a2ff',
                //     textColor: '4d5055'
                // }}
                />
            </div>
            <footer className="FOOTER">
                <div className="frame-13">
                    <div className="COPYRIGHT-wrapper">
                        <div className="COPYRIGHT">
                            <img className="image" alt="Image" src="/img/image-1.png" />
                            <div className="frame-14">
                                <img className="group-2" alt="Group" src="/img/group.png" />
                                <div className="digiplumber-s-l"><FormattedMessage id="copyright" defaultMessage="Digiplumber S.L" /></div>
                            </div>
                        </div>
                    </div>
                    <div className="SN">
                        <p className="text-wrapper-12"><FormattedMessage id="follow_us" defaultMessage="Follow us and be updated to our new activities" /></p>
                        <div className="frame-15">
                            <div className="frame-16">
                                <div className="facebook-rounded" />
                                <div className="text-wrapper-13">Facebook</div>
                            </div>
                            <div className="frame-17">
                                <div className="instagram-rounded" />
                                <div className="text-wrapper-13">Instagram</div>
                            </div>
                            <div className="frame-17">
                                <div className="linkedin-rounded" />
                                <div className="text-wrapper-13">LinkedIn</div>
                            </div>
                            <div className="frame-17">
                                <div className="youtube-rounded" />
                                <div className="text-wrapper-13">YouTube</div>
                            </div>
                            <div className="frame-17">
                                <div className="whatsapp-rounded" />
                                <div className="text-wrapper-13">WhatsApp</div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
};
