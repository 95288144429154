{
    "copyright": "© 2023 Digiplumber",
    "demo": "Demo",
    "digital_exclusion": "Los mayores son excluidos digitalmente mientras que los estudiantes son educados digitalmente.",
    "follow_us": "Síganos y manténgase al día de nuestras nuevas actividades",
    "help_digiplumber": "Cómo funciona Digiplumber",
    "price": "Precio",
    "service": "Servicio",
    "step1": "Paso 1",
    "step1_description": "Usuario reservar una llamada en el calendario",
    "step2": "Paso 2",
    "step2_description": "La llamada tiene lugar entre el agente y el mayor",
    "step3": "Paso 3",
    "step3_description": "Senior instalar la aplicación y dar permiso con OTP para hacer una llamada a la demo",
    "step4": "Paso 4",
    "step4_description": "Realice una sesión sencilla para validar que los mayores entienden el proceso",
    "test_now": "EMPEZAR TEST"
}
