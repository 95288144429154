{
    "copyright": "© 2023 Digiplumber",
    "demo": "Démo",
    "digital_exclusion": "Les seniors sont exclus digitalement alors que les étudiants sont digitalement éduqués.",
    "follow_us": "Suivez-nous et rester informé de nos dernières actualités",
    "help_digiplumber": "Comment Digiplumber fonctionne?",
    "price": "Tarifs",
    "service": "Service",
    "step1": "Étape 1",
    "step1_description": "Prendre un RDV",
    "step2": "Étape 2",
    "step2_description": "Session entre Digiplumber et le client",
    "step3": "Étape 3",
    "step3_description": "Installer, donner accès avec identité et mot de passe à usage unique (OTP) pour faire une démo",
    "step4": "Étape 4",
    "step4_description": "Session simple pour vérifier que le client a bien compris Digiplumber",
    "test_now": "Démarrer le test"
}
