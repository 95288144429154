// calendlyUtils.js
import { getUserLocale } from 'get-user-locale';

export function getCalendlyURL() {
    const userLanguage = getUserLocale();

    const urlMap = {
        es: 'https://calendly.com/d/2bc-fgp-k42/digiplumber?hide_landing_page_details=1&hide_gdpr_banner=1',
        fr: 'https://calendly.com/d/yrf-rvq-g53/digiplumber?hide_landing_page_details=1&hide_gdpr_banner=1',
        // Add more language codes and corresponding URLs as needed
    };

    return urlMap[userLanguage] || urlMap["es"]; // Default URL if user's language is not found in the map
}
