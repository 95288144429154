import React from "react";
import ReactDOMClient from "react-dom/client";
import { IntlProvider } from "react-intl";
import { getUserLocale } from 'get-user-locale';

import { Tablet } from "./screens/Tablet";
import { Web } from "./screens/Web";

const locales = {
    'es': require('./resources/strings/es.json'),
    'en': require('./resources/strings/en.json'),
    'fr': require('./resources/strings/fr.json')
};


const userLocale = getUserLocale();
const translatedTexts = locales[userLocale] || locales['en'];

const app = document.getElementById("app");
const root = ReactDOMClient.createRoot(app);
root.render(
    <React.StrictMode>
        <IntlProvider locale={userLocale} messages={translatedTexts}>
            <Web />
            {/* <Tablet /> */}
        </IntlProvider>
    </React.StrictMode>
);
