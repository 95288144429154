import React from "react";
import { InlineWidget } from "react-calendly";
import { FormattedMessage } from 'react-intl';
import { getCalendlyURL } from '../../utils/calendlyUtils.js';
import "./style.css";

const calendlyUrl = getCalendlyURL();
export const Tablet = () => {
    return (
        <div className="TABLET">
            <div className="TABLET-wrapper">
                <div className="div">
                    <header className="HEADER">
                        <div className="frame">
                            <div className="LOGO">
                                <div className="logo-img">
                                    <div
                                        className="overlap-group"
                                        style={{
                                            backgroundImage: "url(/img/vector-1-1.svg)",
                                        }}
                                    >
                                        <img className="vector" alt="Vector" src="/img/vector-2.svg" />
                                        <img className="img" alt="Vector" src="/img/vector-3.svg" />
                                    </div>
                                </div>
                                <img className="logo-text" alt="Logo text" src="/img/logo-text.png" />
                            </div>
                            <div className="SART">
                                <div className="test-now-wrapper">
                                    <div className="test-now">EMPEZAR TEST</div>
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className="INTRO">
                        <div className="overlap">
                            <img className="vector-2" alt="Vector" src="/img/vector-6.svg" />
                            <p className="seniors-are">
                                &#34;Los mayores son excluidos digitalmente mientras que los estudiantes son educados digitalmente.&#34;
                            </p>
                            <div className="div-wrapper">
                                <div className="text-wrapper">TEST NOW</div>
                            </div>
                            <img className="vector-3" alt="Vector" src="/img/vector-13.svg" />
                            <img
                                className="girl-teaching-her"
                                alt="Girl teaching her"
                                src="/img/girl-teaching-her-grandfather-how-use-laptop-1-1.png"
                            />
                        </div>
                    </div>
                    <p className="p">Ayude a Digiplumber a mejorar nuestro servicio</p>
                    <div className="STEP">
                        <div className="overlap-group-wrapper">
                            <div className="overlap-group-2">
                                <div className="rectangle" />
                                <img className="vector-4" alt="Vector" src="/img/vector-12.svg" />
                                <p className="text-wrapper-2">Senior reservar una llamada en el calendario</p>
                                <img className="vector-5" alt="Vector" src="/img/vector-4.svg" />
                                <div className="frame-2">
                                    <div className="text-wrapper-3">Paso 1</div>
                                </div>
                            </div>
                        </div>
                        <div className="STEP-2">
                            <p className="text-wrapper-4">La llamada tiene lugar entre el agente y el mayor</p>
                            <div
                                className="vector-wrapper"
                                style={{
                                    backgroundImage: "url(/img/vector-12-1.svg)",
                                }}
                            >
                                <img className="vector-6" alt="Vector" src="/img/vector-5.svg" />
                            </div>
                            <div className="frame-3">
                                <div className="text-wrapper-5">Paso 2</div>
                            </div>
                        </div>
                    </div>
                    <div className="STEP-3">
                        <div className="STEP-4">
                            <p className="text-wrapper-6">
                                Senior instalar la aplicación y dar permiso con OTP para hacer una llamada a la demo
                            </p>
                            <div className="frame-4">
                                <div className="text-wrapper-7">Paso 3</div>
                            </div>
                            <div
                                className="overlap-group-3"
                                style={{
                                    backgroundImage: "url(/img/vector-13-1.svg)",
                                }}
                            >
                                <img className="vector-7" alt="Vector" src="/img/vector-7.svg" />
                                <img className="vector-8" alt="Vector" src="/img/vector-11.svg" />
                            </div>
                        </div>
                        <div className="overlap-wrapper">
                            <div className="overlap-2">
                                <div className="rectangle-2" />
                                <p className="text-wrapper-8">
                                    Realice una sesión sencilla para validar que los mayores entienden el proceso
                                </p>
                                <div className="frame-5">
                                    <div className="text-wrapper-9">Paso 4</div>
                                </div>
                                <img className="vector-9" alt="Vector" src="/img/vector-13-2.svg" />
                                <img className="vector-10" alt="Vector" src="/img/vector-10.svg" />
                            </div>
                        </div>
                    </div>
                    <div className="INFO">
                        <div className="frame-6">
                            <h1 className="h-1">Concierte una llamada con un agente de Digiplumber para hacer una prueba</h1>
                            <div >
                                <InlineWidget
                                    url={calendlyUrl}
                                    styles={{
                                        height: '400px'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="START">
                        <div className="test-now">EMPEZAR TEST</div>
                    </div>
                    <footer className="FOOTER">
                        <div className="SN">
                            <p className="text-wrapper-17">Síganos y manténgase al día de nuestras nuevas actividades</p>
                            <div className="frame-16">
                                <div className="frame-17">
                                    <div
                                        className="facebook-rounded"
                                        style={{
                                            backgroundImage: "url(/img/container.svg)",
                                        }}
                                    />
                                    <div className="text-wrapper-18">Facebook</div>
                                </div>
                                <div className="frame-18">
                                    <div
                                        className="instagram-rounded"
                                        style={{
                                            backgroundImage: "url(/img/container-1.svg)",
                                        }}
                                    />
                                    <div className="text-wrapper-19">Instagram</div>
                                </div>
                                <div className="frame-19">
                                    <div
                                        className="linkedin-rounded"
                                        style={{
                                            backgroundImage: "url(/img/container-2.svg)",
                                        }}
                                    />
                                    <div className="text-wrapper-20">Linkedin</div>
                                </div>
                                <div className="frame-20">
                                    <div
                                        className="youtube-rounded"
                                        style={{
                                            backgroundImage: "url(/img/container-3.svg)",
                                        }}
                                    />
                                    <div className="text-wrapper-21">Youtube</div>
                                </div>
                                <div className="frame-21">
                                    <div
                                        className="whatsapp-rounded"
                                        style={{
                                            backgroundImage: "url(/img/container-4.svg)",
                                        }}
                                    />
                                    <div className="text-wrapper-22">Whatsapp</div>
                                </div>
                            </div>
                        </div>
                        <div className="COPYRIGHT">
                            <div className="logo-img-wrapper">
                                <div className="logo-img-2">
                                    <div
                                        className="overlap-group-4"
                                        style={{
                                            backgroundImage: "url(/img/vector-1-2.svg)",
                                        }}
                                    >
                                        <img className="vector-13" alt="Vector" src="/img/vector-2-1.svg" />
                                        <img className="vector-14" alt="Vector" src="/img/vector-3-1.svg" />
                                    </div>
                                </div>
                            </div>
                            <div className="frame-22">
                                <img className="group" alt="Group" src="/img/group.png" />
                                <div className="text-wrapper-23">2023 Digiplumber Platform S.L</div>
                            </div>
                        </div>
                    </footer>
                </div>
            </div>
        </div>
    );
};
