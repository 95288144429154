{
    "copyright": "© 2023 Digiplumber",
    "demo": "Demo",
    "digital_exclusion": "Seniors are digitally excluded while students are digitally educated.",
    "follow_us": "Follow us & stay connected with our latest news",
    "help_digiplumber": "How Digiplumber works?",
    "price": "Price",
    "service": "Service",
    "step1": "Step 1",
    "step1_description": "Client books a call on the calendar ",
    "step2": "Step 2",
    "step2_description": "Call takes place between Digiplumber and client",
    "step3": "Step 3",
    "step3_description": "Client installs and gives permission with OTP to make a call to demo",
    "step4": "Step 4",
    "step4_description": "Organise a simple session to validate client understanding",
    "test_now": "TEST NOW"
}
